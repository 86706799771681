<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            codeGroupCd="RELATED_LAWS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="relatedLawsCd"
            label="관련법규"
            v-model="searchParam.relatedLawsCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            name="search"
            placeholder="인허가 종류명"
            label="인허가 종류명"
            v-model="searchParam.limLicenseKindName"
          ></c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="인허가 현황 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group>
            <c-btn label="인허가 직접등록" :editable="editable" icon="add" @btnClicked="licensePop" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "license-status",
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'licenseNo',
            field: 'licenseNo',
            label: '인허가번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            label: '인허가종류',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'licenseStartDate',
            field: 'licenseStartDate',
            label: '인허가시작일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'licenseEndDate',
            field: 'licenseEndDate',
            label: '인허가종료일',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'relatedDeptName',
            field: 'relatedDeptName',
            label: '관련부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'licenseCreateTypeName',
            field: 'licenseCreateTypeName',
            label: '인허가 등록 구분',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
          {
            name: 'sopMocId',
            field: 'sopMocId',
            label: '관련MOC',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        limLicenseKindName: '',
        relatedLawsCd: null,
      },
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sop.lim.lincense.card.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.licensePop(row);
    },
    licensePop(result) {
      this.popupOptions.target = () => import(`${"./licenseStatusDetail.vue"}`);
      this.popupOptions.title = '인허가 상세';
      this.popupOptions.param = {
        limLicenseId: result ? result.limLicenseId : '',
      };
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
